import { IResponseProjectDirectPermissionMasterData } from './../models/interface/projectList.interface';
import { RoutesEnum } from "../../../models/enums";
import { IApiBaseResponse } from "../../../models/interface";
import { ApiService } from "../../shared/services";
import { IProjectFormData, IProjectQuestions, IQuestionFormData, IRequestCheckProjectUniqueSlug, IRequestCheckQuestionUniqueVariable, IRequestProjectListData, IRequestShowAllRoles, IResponseAddEditQuestion, IResponseCreateEditProject, IResponseProjectDetails, IResponseProjectListData, IResponseProjectRoleMasterData, IResponseProjectTagListData, IResponseQuestionType, IRequestProjectRecordsData, IResponseProjectRecords, IProjectReportAudit, IResponseRecordsExcelData, IRequestExportProjectReport, IRequestChangeQuestionType, ICodingReviewSeries, IRequestCodingReviewData, IResponseCodingReview, IRecordTimelineData, IRequestCheckOrgProjectLimit } from '../models/interface';
import { IErrorSamplingDateRange, IErrorSamplingExcelBufferParams, IErrorSamplingRecord, IErrorSamplingVariables } from '../store/projectErrorSamplingStore';
import { URLService } from './url.service';

export class ProjectListService {

    public async getProjectListData(params: IRequestProjectListData) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseProjectListData>>(RoutesEnum.ORG_GET_PROJECT_LIST, { params })
    }

    /**
     * get Project Details
     */
    public async getProjectDetailsData(project_slug: string) {
        const forceUpdateURL = RoutesEnum.ORG_GET_PROJECT_DETAILS.replace(
            "{project_slug}",
            project_slug.toString()
        );
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseProjectDetails>>(forceUpdateURL)
    }

    public async getProjectRecordList(params: IRequestProjectRecordsData) {
        const recordListURL = RoutesEnum.ORG_GET_PROJECT_RECORDS.replace(
            "{project_slug}",
            params.project_slug.toString()
        );
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseProjectRecords>>(recordListURL,{params})
    }
    
    public async getProjectRoleMasterData(params: IRequestShowAllRoles) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseProjectRoleMasterData>>(RoutesEnum.ORG_GET_PROJECT_ROLE_MASTER_DATA, {params})
    }

    public async getDirectPermissionMasterData() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseProjectDirectPermissionMasterData>>(RoutesEnum.ORG_GET_PROJECT_DIRECT_PERMISSION_MASTER_DATA, {})
    }

    public async getProjectTagListData() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseProjectTagListData>>(RoutesEnum.ORG_GET_TAG_LIST, {})
    }

    public async checkProjectUniqueSlug(params: IRequestCheckProjectUniqueSlug) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{exists: boolean}>>(RoutesEnum.ORG_CHECK_PROJECT_UNIQUE_SLUG, {params})
    }

    public async checkOrgLimit(params: IRequestCheckOrgProjectLimit) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{ is_allowed: boolean; limit_message: string }>>(RoutesEnum.ORG_CHECK_LIMIT, {params})
    }

    public async createProject(payload: IProjectFormData) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseCreateEditProject>>(RoutesEnum.ORG_CREATE_PROJECT, payload)
    }

    public async editProject(payload: IProjectFormData) {
        const forcedURL = RoutesEnum.ORG_EDIT_PROJECT.replace(
            "{project_slug}",
            (payload.slug ?? '').toString()
        );
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseCreateEditProject>>(forcedURL, payload)
    }

    public async favoriteProject(projectSlug: string, is_favourite?: boolean) {
        const _is_favourite = is_favourite ?? false
        const forcedURL = RoutesEnum.ORG_PROJECT_FAVORITE.replace(
            "{project_slug}",
            (projectSlug).toString()
        );
        return (await new ApiService().privateClient()).post<IApiBaseResponse<{is_favourite: boolean}>>(forcedURL, {is_favourite: !_is_favourite})
    }

    public async manageProjectPermissions(payload: IProjectFormData) {
        const forcedURL = RoutesEnum.ORG_MANAGE_PROJECT_PERMISSIONS.replace(
            "{project_slug}",
            (payload.slug ?? '').toString()
        );
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseCreateEditProject>>(forcedURL, payload)
    }

    /**
        * Update Project Questions Order
    */
    public async updateQuestionOrder(project_slug: string, orderedQuestion: {question_id: number, parent_question_id: number, question_order: number}[]) {
        const forceUpdateURL = RoutesEnum.ORG_UPDATE_PROJECT_QUESTIONS_ORDER.replace(
            "{project_slug}",
            project_slug.toString()
        );
        return (await new ApiService().privateClient()).post<IApiBaseResponse<{ questions: IProjectQuestions[]}>>(forceUpdateURL, {
            questions: orderedQuestion
        })
    }

    /**
     * Question api
    */
    public async getQuestionTypeList() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseQuestionType>>(RoutesEnum.ORG_GET_PROJECT_QUESTION_TYPE)
    }

    public async checkUniqueQuestionVariable(params: IRequestCheckQuestionUniqueVariable) {
        const forcedURL = RoutesEnum.ORG_CHECK_PROJECT_QUESTION_UNIQUE_VARIABLE.replace(
            "{project_slug}",
            (params.project_slug).toString()
        );
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{exists: boolean}>>(forcedURL, { params })
    }

    public async addEditQuestion(payload: IQuestionFormData) {
        const forcedURL = RoutesEnum.ORG_ADD_EDIT_PROJECT_QUESTION.replace(
            "{project_slug}",
            (payload.project_slug).toString()
        );
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseAddEditQuestion>>(forcedURL, {...payload})
    }

    public async deleteQuestions(project_slug:string, question_ids: number[]) {
        const forceUpdateURL = RoutesEnum.ORG_DELETE_PROJECT_QUESTIONS.replace(
            "{project_slug}",
            project_slug.toString()
        );
        return (await new ApiService().privateClient()).delete<IApiBaseResponse<{project_id: number, project_slug: string, question_ids: number[]}>>(forceUpdateURL, {data:{question_ids}})
    }

    public async cloneProject(project_slug:string, new_project_title: string, should_associate_users: boolean) {
        const forceUpdateURL = RoutesEnum.ORG_PROJECT_CLONE.replace(
            "{project_slug}",
            project_slug.toString()
        );
        return (await new ApiService().privateClient()).post<IApiBaseResponse<{newProjectDetails:{project_id: number; project_slug: string;}}>>(forceUpdateURL, {new_project_title, should_associate_users})
    }

    public async getProjectReportAuditData(project_slug:string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_GET_PROJECT_REPORT_AUDIT);
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{auditList: IProjectReportAudit[]}>>(updatedURL);
    }
    
    public async exportProjectRecordList(payload: IRequestExportProjectReport) {
        const { updatedURL, updatedData } = new URLService<IRequestExportProjectReport>(payload).formatURL(RoutesEnum.ORG_EXPORT_PROJECT_RECORDS);
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseRecordsExcelData>>(updatedURL, updatedData);
    }
    
    public async downloadProjectRecord(params: {file_path_name: string}, project_slug:string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_DOWNLOAD_REPORT);
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{fileData: Buffer}>>(updatedURL, { params });
    }
    
    public async exportProjectStandardCodebook(payload: IRequestExportProjectReport) {
        const { updatedURL, updatedData } = new URLService<IRequestExportProjectReport>(payload).formatURL(RoutesEnum.ORG_EXPORT_PROJECT_STANDARD_CODEBOOK);
        return (await new ApiService().privateClient()).post<IApiBaseResponse<{fileData: Buffer}>>(updatedURL, updatedData);
    }
    
    public async changeQuestionType(payload: IRequestChangeQuestionType, project_slug:string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_CHANGE_QUESTION_TYPE);
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseAddEditQuestion>>(updatedURL, payload);
    }
    
    public async getCodingReviewFilters(project_slug:string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_CODING_REVIEW_FILTERS);
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{filtersData: ICodingReviewSeries[]}>>(updatedURL);
    }
    
    public async getCodingReviewData(params: IRequestCodingReviewData, project_slug:string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_CODING_REVIEW_DATA);
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseCodingReview>>(updatedURL, { params });
    }
    
    public async deleteProject({project_slug}: {project_slug: string}) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_DELETE);
        return (await new ApiService().privateClient()).delete<IApiBaseResponse<{project_slug: string}>>(updatedURL);
    }
    
    public async getRecordsTimelineViewData(project_slug: string, params: { question_id: string | null; search?: string | null;}) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_RECORDS_TIMELINE_VIEW_DATA);
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{records: IRecordTimelineData[]}>>(updatedURL, { params });
    }

    public async getErrorSamplingData(project_slug: string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_ERROR_SAMPLING_DATA);
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{record_list: IErrorSamplingRecord[], variable_list: IErrorSamplingVariables[], date_range: IErrorSamplingDateRange }>>(updatedURL);
    }

    public async getErrorSamplingExportBuffer(project_slug:string, payload: IErrorSamplingExcelBufferParams) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_ERROR_SAMPLING_EXPORT_DATA);
        return (await new ApiService().privateClient()).post<IApiBaseResponse<{ excelBuffer: IErrorSamplingRecord[] }>>(updatedURL, payload);
    }
}

